<template>
  <a-modal width="50%" :label-col="4" :wrapper-col="14" :visible="open" @ok="onOk" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>选择明星</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('明星.明星分类')" prop="starCategory">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('明星.明星分类')" style="width: 100%"
                          v-model="queryParam.starCategory" allowClear>
                  <a-select-option v-for="(item, index) in this.categoryList" :value="item.id+''" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('明星.状态')" prop="status">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('明星.状态')" style="width: 100%" v-model="queryParam.status" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.StatusEnum" :value="item.type" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('明星.明星名字')" prop="starName">
                <a-input v-model="queryParam.starName" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('明星.明星名字')"
                         allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon
                  type="search"/>{{ $t('通用.按钮.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon
                  type="redo"/>{{ $t('通用.按钮.重置') }}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl"/>
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['star:star:add']">
          <a-icon type="plus" />{{$t('通用.按钮.新增')}}
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, type: 'radio', onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <template slot="picture" slot-scope="text, record">
          <div>
            <img v-if="record.picture" :src="record.picture" style="width:60px;height:60px;"
                 @click="handleRecordPreview(record.picture)" alt="none"/>
          </div>
        </template>
        <template slot="backgroundPicture" slot-scope="text, record">
          <div>
            <img v-if="record.backgroundPicture" :src="record.backgroundPicture" style="width:80px;height:62px;"
                 @click="handleRecordPreview(record.backgroundPicture)" alt="none"/>
          </div>
        </template>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </a-modal>
</template>

<script>
import {pageStar} from '@/api/star/star'
import {mapGetters} from 'vuex'
import CreateForm from './CreateForm'
import CustomDictTag from "@/components/DictCustomTag";
import {listCategory} from "@/api/star/category";
import Editor from "@/components/Editor/Editor";
import { tableMixin } from '@/store/table-mixin'


export default {
  name: 'StarSelectForm',
  props: {},
  components: {
    Editor,
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      selectedRowKeys: [],
      selectedRows: [],
      categoryList: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      open: false,
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        starCategory: null,
        starName: null,
        picture: null,
        status: undefined,
        backgroundPicture: null,
        fansNum: null,
        followNum: null,
        starInfo: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('明星.明星分类'),
          dataIndex: 'starCategoryName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('明星.明星名字'),
          dataIndex: 'starName',
          ellipsis: true,
          align: 'center'
        },

        {
          title: this.$t('明星.明星照片'),
          dataIndex: 'picture',
          scopedSlots: { customRender: 'picture' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('明星.背景图'),
          dataIndex: 'backgroundPicture',
          scopedSlots: { customRender: 'backgroundPicture' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('明星.基础粉丝数'),
          dataIndex: 'fansNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('明星.关注数'),
          dataIndex: 'followNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('明星.状态'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getCategoryList();
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.resetQuery()
    },
    openStar(id) {
      if (id) {
        this.selectedRowKeys= [id]
      }
      this.open = true;
    },
    onOk() {
      //判断有没有选择,拿到列表里选择的
      this.open = false
      this.$emit('select', this.selectedRows[0])
      this.resetQuery()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.resetQuery()
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询明星列表 */
    getList () {
      this.loading = true
      pageStar(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.selectedRowKeys= [];
      this.selectedRows= [];
      this.queryParam = {
        starCategory: undefined,
        starName: undefined,
        picture: undefined,
        status: undefined,
        backgroundPicture: undefined,
        fansNum: undefined,
        followNum: undefined,
        starInfo: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    getCategoryList() {
      listCategory().then(response => {
        this.categoryList = response.data
      })
    },
  }
}
</script>
